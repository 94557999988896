import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid"

import type { Coordinates } from "../api"

function googleMapsDrivingDirectionsUrl(coordinates: Coordinates): string {
  const url = new URL("https://www.google.com/maps/dir/?api=1")
  url.searchParams.append(
    "destination",
    coordinates.latitude + "," + coordinates.longitude
  )
  return url.toString()
}

type LocationLinkProps = {
  fixedLocationAddress: string
  fixedLocationCoordinates: Coordinates
}

const LocationLink: React.FC<LocationLinkProps> = ({
  fixedLocationAddress,
  fixedLocationCoordinates,
}) => {
  return (
    <a
      className="text-indigo-600 underline"
      href={googleMapsDrivingDirectionsUrl(fixedLocationCoordinates)}
      rel="noreferrer"
      target="_blank"
    >
      {fixedLocationAddress}{" "}
      <ArrowTopRightOnSquareIcon className="mb-1 inline h-3" />
    </a>
  )
}

export default LocationLink
