import type React from "react"

import type { ActiveStylist } from "../api"
import type { CountedOfferedService } from "../types"

import { sendGAEvent } from "../util"
import PlusMinusButton from "./PlusMinusButton"

type ServiceKey = number

type ServicesPickerProps = {
  disabled?: boolean
  readOnly?: boolean
  services: CountedOfferedService[]
  totalCostCents: number
  totalDurationMinutes: number
  stylist?: ActiveStylist
  onAddService?: (id: ServiceKey) => void
  onRemoveService?: (id: ServiceKey) => void
}

const ServicesPicker: React.FC<ServicesPickerProps> = ({
  disabled,
  onAddService,
  onRemoveService,
  readOnly,
  services,
  stylist,
  totalCostCents,
  totalDurationMinutes,
}) => {
  const addService = (id: ServiceKey) => {
    sendGAEvent("appointments", "add service", services[id].name)
    onAddService != null && onAddService(id)
  }
  const removeService = (id: ServiceKey) => {
    sendGAEvent("appointments", "remove service", services[id].name)
    onRemoveService != null && onRemoveService(id)
  }

  return (
    <div className="bg-white px-4 pb-5 shadow sm:rounded-lg sm:p-6 pt-4">
      <fieldset>
        <legend className="text-lg font-medium text-gray-900">Services</legend>
        <div className="mt-4 border-t border-b border-gray-200">
          {services.map((service, idx) => (
            <div
              key={`${service.id}:${service.duration_minutes}:${service.cost_cents}`}
              className="grid select-none grid-cols-4 py-4 text-sm items-center"
            >
              <span className="col-span-2">
                <PlusMinusButton
                  disabled={disabled}
                  onDecrement={() => removeService(idx)}
                  onIncrement={() => addService(idx)}
                  readOnly={readOnly}
                  value={service.count}
                />
                <span className="font-medium text-gray-700">
                  {stylist?.service_categories_by_id[service.category_id].name}{" "}
                  / {service.name}
                </span>
              </span>
              <span className="justify-self-end font-medium text-gray-700">
                {service.duration_minutes} min.
              </span>
              <b className="justify-self-end text-gray-700">
                ${(service.cost_cents / 100).toFixed(2)}
              </b>
            </div>
          ))}
        </div>
        <div className="grid select-none grid-cols-4 pt-4 text-sm font-medium text-gray-700">
          <span className="col-span-2 text-gray-500">Total</span>
          <b className="justify-self-end">{totalDurationMinutes} min.</b>
          <b className="justify-self-end">
            ${(totalCostCents / 100).toFixed(2)}
          </b>
        </div>
      </fieldset>
    </div>
  )
}

export default ServicesPicker
