// This file is not generated.  However, everthing in the "generated" subdirectory /is/.
// This file simply re-exports everything that's generated, with a little bit of module
// initialization to set the right API endpoint.  This way, it integrates with our
// different build targets, doesn't commit any endpoint into VCS, and is very flexible
// for development needs.

import { DateTime } from "luxon"

import { OpenAPI } from "./generated"

import type {
  AvailableSlot,
  PaymentMethodForStylist,
  Stylist,
} from "./generated"

OpenAPI.BASE = process.env.REACT_APP_BASE_API_URL!.replace(/\/$/, "")

export type Coordinates = NonNullable<Stylist["fixed_location_coordinates"]>

export type PaymentMethodDetail = Required<PaymentMethodForStylist>["detail"]

export type ActiveAvailableSlot = AvailableSlot & {
  startDateTime: DateTime
  endDateTime: DateTime
}

export function activateAvailableSlot(s: AvailableSlot): ActiveAvailableSlot {
  return Object.assign(s, {
    startDateTime: DateTime.fromISO(s.start),
    endDateTime: DateTime.fromISO(s.end),
  })
}

export type ServiceCategory = Stylist["service_categories"][0]
export type OfferedService = Stylist["offered_services"][0]

export type LinkedServiceCategory = ServiceCategory & {
  offered_services: OfferedService[]
}

export type LinkedOfferedService = OfferedService & {
  category: ServiceCategory
}

export type ActiveStylist = Stylist & {
  availabilityMinDate: DateTime
  availabilityMaxDate: DateTime

  service_categories: LinkedServiceCategory[]
  offered_services: LinkedOfferedService[]

  service_categories_by_id: Record<number, LinkedServiceCategory>
}

export function activateStylist(s: Stylist): ActiveStylist {
  const now = DateTime.local()
  const calc_date = (days: number) => now.plus({ days })

  const service_categories: LinkedServiceCategory[] = s.service_categories.map(
    cat =>
      Object.assign({}, cat, {
        offered_services: s.offered_services.filter(
          ser => ser.category_id === cat.id
        ),
      })
  )

  const service_categories_by_id = Object.fromEntries(
    service_categories.map(cat => [cat.id, cat])
  )

  function reallyFind<T>(arr: T[], f: (a0: T) => boolean): T {
    const el = arr.find(f)
    if (el !== undefined) return el
    throw new Error()
  }

  const offered_services: LinkedOfferedService[] = s.offered_services.map(ser =>
    Object.assign({}, ser, {
      category: reallyFind(
        s.service_categories,
        cat => cat.id === ser.category_id
      ),
    })
  )

  return Object.assign(s, {
    availabilityMinDate: calc_date(s.availability_min_days),
    availabilityMaxDate: calc_date(s.availability_max_days),
    service_categories,
    offered_services,
    service_categories_by_id,
  })
}

export * from "./generated"
