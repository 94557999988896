/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AvailableSlot } from '../models/AvailableSlot';
import type { CheckoutSession } from '../models/CheckoutSession';
import type { ClientAppointment } from '../models/ClientAppointment';
import type { ClientInvitation } from '../models/ClientInvitation';
import type { ClientInvitationAcceptance } from '../models/ClientInvitationAcceptance';
import type { ClientInvitationDeclination } from '../models/ClientInvitationDeclination';
import type { ClientMessage } from '../models/ClientMessage';
import type { ClientNotificationPreferences } from '../models/ClientNotificationPreferences';
import type { ClientOnboardingStatus } from '../models/ClientOnboardingStatus';
import type { ClientPhoneNumberValidation } from '../models/ClientPhoneNumberValidation';
import type { ClientProfile } from '../models/ClientProfile';
import type { ClientRefreshedAppointment } from '../models/ClientRefreshedAppointment';
import type { ClientTermsAcceptance } from '../models/ClientTermsAcceptance';
import type { ClientTimezone } from '../models/ClientTimezone';
import type { ClientTimezoneList } from '../models/ClientTimezoneList';
import type { ClientTimezoneSetting } from '../models/ClientTimezoneSetting';
import type { NewRequest } from '../models/NewRequest';
import type { PaymentMethodForStylist } from '../models/PaymentMethodForStylist';
import type { PrivateClientInvitation } from '../models/PrivateClientInvitation';
import type { Stylist } from '../models/Stylist';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ClientsService {

    /**
     * @returns ClientTimezoneList
     * @throws ApiError
     */
    public static retrieveClientTimezoneList(): CancelablePromise<ClientTimezoneList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/clients/timezone-list',
        });
    }

    /**
     * @returns ClientTimezone
     * @throws ApiError
     */
    public static retrieveClientTimezone({
        latitude,
        longitude,
    }: {
        latitude?: number,
        longitude?: number,
    }): CancelablePromise<ClientTimezone> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/clients/timezone-suggestion',
            query: {
                'latitude': latitude,
                'longitude': longitude,
            },
        });
    }

    /**
     * @returns ClientPhoneNumberValidation
     * @throws ApiError
     */
    public static retrieveClientPhoneNumberValidation({
        phoneNumber,
    }: {
        phoneNumber: string,
    }): CancelablePromise<ClientPhoneNumberValidation> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/clients/phone-validation/{phone_number}',
            path: {
                'phone_number': phoneNumber,
            },
        });
    }

    /**
     * @returns ClientProfile
     * @throws ApiError
     */
    public static retrieveClientProfile(): CancelablePromise<ClientProfile> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/clients/profile2',
        });
    }

    /**
     * @returns ClientProfile
     * @throws ApiError
     */
    public static updateClientProfile({
        requestBody,
    }: {
        requestBody?: ClientProfile,
    }): CancelablePromise<ClientProfile> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/clients/profile2',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClientTimezoneSetting
     * @throws ApiError
     */
    public static retrieveClientTimezoneSetting(): CancelablePromise<ClientTimezoneSetting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/clients/timezone',
        });
    }

    /**
     * @returns ClientTimezoneSetting
     * @throws ApiError
     */
    public static updateClientTimezoneSetting({
        requestBody,
    }: {
        requestBody?: ClientTimezoneSetting,
    }): CancelablePromise<ClientTimezoneSetting> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/clients/timezone',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClientOnboardingStatus
     * @throws ApiError
     */
    public static retrieveClientOnboardingStatus(): CancelablePromise<ClientOnboardingStatus> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/clients/onboarding-status',
        });
    }

    /**
     * @returns Stylist
     * @throws ApiError
     */
    public static retrieveStylist({
        stylistUuid,
    }: {
        stylistUuid: string,
    }): CancelablePromise<Stylist> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/clients/stylist-profile/{stylist_uuid}',
            path: {
                'stylist_uuid': stylistUuid,
            },
        });
    }

    /**
     * @returns ClientNotificationPreferences
     * @throws ApiError
     */
    public static retrieveClientNotificationPreferences(): CancelablePromise<ClientNotificationPreferences> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/clients/notification-prefs/',
        });
    }

    /**
     * @returns ClientNotificationPreferences
     * @throws ApiError
     */
    public static partialUpdateClientNotificationPreferences({
        requestBody,
    }: {
        requestBody?: ClientNotificationPreferences,
    }): CancelablePromise<ClientNotificationPreferences> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/clients/notification-prefs/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static listClientsProfiles(): CancelablePromise<Array<any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/clients/profile/',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static createClientsProfile({
        requestBody,
    }: {
        requestBody?: any,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/clients/profile/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PaymentMethodForStylist
     * @throws ApiError
     */
    public static retrievePaymentMethodForStylist({
        stylistUuid,
    }: {
        stylistUuid: string,
    }): CancelablePromise<PaymentMethodForStylist> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/clients/payment-method2/{stylist_uuid}',
            path: {
                'stylist_uuid': stylistUuid,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static listClientsAppointmentTemplates(): CancelablePromise<Array<any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/clients/appt_invite_list/',
        });
    }

    /**
     * @returns ClientInvitation
     * @throws ApiError
     */
    public static retrieveClientInvitation({
        invitationUuid,
    }: {
        invitationUuid: string,
    }): CancelablePromise<ClientInvitation> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/clients/appt-invite/{invitation_uuid}',
            path: {
                'invitation_uuid': invitationUuid,
            },
        });
    }

    /**
     * @returns PrivateClientInvitation
     * @throws ApiError
     */
    public static retrievePrivateClientInvitation({
        invitationUuid,
    }: {
        invitationUuid: string,
    }): CancelablePromise<PrivateClientInvitation> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/clients/appt-invite-auth/{invitation_uuid}',
            path: {
                'invitation_uuid': invitationUuid,
            },
        });
    }

    /**
     * @returns ClientAppointment
     * @throws ApiError
     */
    public static listClientAppointments({
        fromDatetime,
        notStates,
        states,
        toDatetime,
    }: {
        fromDatetime?: string,
        notStates?: Array<'tentative' | 'declined' | 'confirmed' | 'canceled' | 'completed' | 'expired' | 'accepted' | 'rescheduled_gone' | 'adjusted_gone' | 'adjusted_original'>,
        states?: Array<'tentative' | 'declined' | 'confirmed' | 'canceled' | 'completed' | 'expired' | 'accepted' | 'rescheduled_gone' | 'adjusted_gone' | 'adjusted_original'>,
        toDatetime?: string,
    }): CancelablePromise<Array<ClientAppointment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/clients/appointment-list/',
            query: {
                'from_datetime': fromDatetime,
                'not_states': notStates,
                'states': states,
                'to_datetime': toDatetime,
            },
        });
    }

    /**
     * @returns ClientAppointment
     * @throws ApiError
     */
    public static retrieveClientAppointment({
        id,
    }: {
        id: string,
    }): CancelablePromise<ClientAppointment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/clients/appointment/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ClientRefreshedAppointment
     * @throws ApiError
     */
    public static retrieveClientRefreshedAppointment({
        id,
    }: {
        id: string,
    }): CancelablePromise<ClientRefreshedAppointment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/clients/refreshed-appointment/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns AvailableSlot
     * @throws ApiError
     */
    public static listAvailableSlots({
        durationMinutes,
        preferredDate,
        stylistUuid,
        address,
        candidateApptId,
        latitude,
        longitude,
    }: {
        durationMinutes: number,
        preferredDate: string,
        stylistUuid: string,
        address?: string,
        candidateApptId?: string,
        latitude?: number,
        longitude?: number,
    }): CancelablePromise<Array<AvailableSlot>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/clients/appointment-suggestions2',
            query: {
                'address': address,
                'candidate_appt_id': candidateApptId,
                'duration_minutes': durationMinutes,
                'latitude': latitude,
                'longitude': longitude,
                'preferred_date': preferredDate,
                'stylist_uuid': stylistUuid,
            },
        });
    }

    /**
     * @returns ClientMessage
     * @throws ApiError
     */
    public static listClientMessages({
        stylistUuid,
    }: {
        stylistUuid?: string,
    }): CancelablePromise<Array<ClientMessage>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/clients/conversation',
            query: {
                'stylist_uuid': stylistUuid,
            },
        });
    }

    /**
     * @returns ClientMessage
     * @throws ApiError
     */
    public static createClientMessage({
        requestBody,
    }: {
        requestBody?: ClientMessage,
    }): CancelablePromise<ClientMessage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/clients/conversation',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CheckoutSession
     * @throws ApiError
     */
    public static createCheckoutSession({
        requestBody,
    }: {
        requestBody?: CheckoutSession,
    }): CancelablePromise<CheckoutSession> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/clients/checkout-session2',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns NewRequest
     * @throws ApiError
     */
    public static createNewRequest({
        requestBody,
    }: {
        requestBody?: NewRequest,
    }): CancelablePromise<NewRequest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/clients/appointments/request',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClientTermsAcceptance
     * @throws ApiError
     */
    public static updateClientTermsAcceptance({
        requestBody,
    }: {
        requestBody?: ClientTermsAcceptance,
    }): CancelablePromise<ClientTermsAcceptance> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/clients/accept-terms',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClientInvitationAcceptance
     * @throws ApiError
     */
    public static partialUpdateClientInvitationAcceptance({
        invitationUuid,
        requestBody,
    }: {
        invitationUuid: string,
        requestBody?: ClientInvitationAcceptance,
    }): CancelablePromise<ClientInvitationAcceptance> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/clients/appt-invite-auth-accept/{invitation_uuid}',
            path: {
                'invitation_uuid': invitationUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ClientInvitationDeclination
     * @throws ApiError
     */
    public static partialUpdateClientInvitationDeclination({
        invitationUuid,
        requestBody,
    }: {
        invitationUuid: string,
        requestBody?: ClientInvitationDeclination,
    }): CancelablePromise<ClientInvitationDeclination> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/clients/appt-invite-auth-decline/{invitation_uuid}',
            path: {
                'invitation_uuid': invitationUuid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
