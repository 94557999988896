import React from "react"

import Spinner from "../components/Spinner"

import type { DateTime } from "luxon"

type Props = {
  loading: boolean
  value: string
  availabilityMinDate?: DateTime
  availabilityMaxDate?: DateTime
  onChange: (a0: string) => void
}

const PreferredDateCard: React.FC<Props> = props => {
  return (
    <div className="bg-white px-4 pb-5 shadow sm:rounded-lg sm:p-6 pt-4">
      <fieldset>
        <legend className="mb-4 w-full border-b border-gray-200 pb-4 text-lg font-medium text-gray-900">
          Preferred date
        </legend>
        {props.loading ? (
          <Spinner />
        ) : props.availabilityMinDate == null ||
          props.availabilityMaxDate == null ? (
          <p>
            Programmer error: availabilityMinDate and availabilityMaxDate must
            be provided.
          </p>
        ) : (
          <>
            <p className="mb-3 text-gray-500">
              Your stylist is accepting appointments from{" "}
              <b className="text-gray-800">
                {props.availabilityMinDate.toFormat("MMMM d, yyyy")}
              </b>{" "}
              to{" "}
              <b className="text-gray-800">
                {props.availabilityMaxDate.toFormat("MMMM d, yyyy")}
              </b>
              .
            </p>
            <p className="mb-4 text-gray-500">
              Within this window, what is your preferred appointment date?
            </p>
            <input
              className="mt-1 block h-10 w-full rounded-md border border-gray-200 px-3 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              type="date"
              min={props.availabilityMinDate.toISODate()}
              max={props.availabilityMaxDate.toISODate()}
              value={props.value || ""}
              onChange={e => props.onChange(e.target.value)}
            />
          </>
        )}
      </fieldset>
    </div>
  )
}

export default PreferredDateCard
